export const data = {
  "Work Law": {
    paragraphs: [
      "You may sustain injuries while on the job. It is crucial that you notify your management immediately if you are harmed on the job. To treat your injury, you must also locate a physician. Your entitlement to treatment may be safeguarded by workers' compensation legislation. Your employer may cover a portion of your medical expenses and salary.",
      "Your personal belongings cannot be viewed by your company. Your briefcases, bags, storage lockers, and purse are all yours. Your manager is not permitted to read any correspondence that has your name. Your supervisor is permitted to view your emails if you use a computer for work. Certain businesses are permitted to listen in on your phone conversations and texts if you use one. Every organization has a different approach to this. You should never publish or speak poorly of your business.",
      "An unfair termination indicates that you were let go for a reason that was against the law. You might consider speaking with a lawyer if you believe that harassment, a work injury, or discrimination caused your termination. Lawyers who support workers' rights are available. Lawyers who assist immigrants and refugees are available for free or at a reasonable cost. Inform your employer of any illegal activity you believe is taking place at work. Document the situation if your employer is unable to assist. Later on, you could require this information.",
      "Your employer is responsible for ensuring that your workplace is risk-free and secure. Things that can hurt you include toxins and dangerous instruments and equipment. You may occasionally have to perform risky tasks as part of your job. A construction worker could have to scale tall buildings, for instance. Employers are required by law to warn you about potential hazards at work. Additionally, they must instruct you in a language you can comprehend. Inform your manager if you suspect or observe something dangerous that they are unaware of. Learn more about your rights to safety at work.",
      "You may be required to work overtime during busy seasons. Employers must pay overtime rates for any hours worked beyond 40 in a week. For example, if you work 45 hours in a week, the extra 5 hours should be paid at 1.5 times your regular hourly rate. It is important to review your paycheck to ensure accurate payments. If you notice errors, report them to your manager immediately. Keep records of your hours worked in case a dispute arises later.",
      "Employers cannot discriminate against you based on age, gender, religion, or disability. For instance, if you are denied a promotion because of your age or nationality, it may violate federal laws. You should document the incident and speak to your Human Resources department. If the issue is not resolved, consider contacting an employment lawyer or filing a complaint with the Equal Employment Opportunity Commission (EEOC).",
      "Employers are required to accommodate religious practices unless it causes significant hardship to the business. For example, if you request a flexible lunch break to observe a prayer schedule, your employer should make reasonable adjustments. If your request is denied without justification, it may be discrimination, and you should document the refusal and seek advice from an attorney.",
      "Workplace safety laws protect employees from hazardous conditions. For example, if you work in a factory and notice exposed wiring or leaking chemicals, you have the right to report the danger without fear of retaliation. Employers must investigate and fix unsafe conditions. If they fail to act, you can contact the Occupational Safety and Health Administration (OSHA) to file a complaint.",
    ],
  },
  "Immigration Law": {
    paragraphs :[
      "Everyone in the U.S. has basic rights regardless of immigration status. These rights include freedom of speech, equality, safety, and privacy. Discrimination based on age, disability, national origin, race, religion, gender identity, or sexual orientation is illegal.",
      "You have the right to safe housing. Landlords cannot discriminate against you or charge extra based on immigration status. Homes must be free from hazards like mold or pests, and reasonable accommodations must be made for disabilities.",
      "Workers have the right to safe workplaces and fair wages. Discrimination and exploitation are illegal, even for undocumented workers. Workers can seek help without revealing their immigration status.",
      "All children have the right to free public education. Schools cannot discriminate based on race or ethnicity.",
      "Emergency medical care must be provided regardless of immigration status or ability to pay. Hospitals are required by law to offer treatment in emergencies.",
      "You have rights when dealing with law enforcement, including the right to remain silent, privacy, and legal representation. Officers need a warrant to search your home or belongings.",
      "Immigrants have the right to apply for legal status and programs like asylum, Temporary Protected Status (TPS), or family reunification visas. Refugees and asylees receive specific benefits, such as cash assistance and employment services.",
      "Victims of domestic violence have access to help, including emergency shelter, counseling, and legal protections like U Visas and the Violence Against Women Act (VAWA).",
      "Stateless individuals and undocumented immigrants retain basic rights like education, healthcare, and protection against discrimination. Programs like DACA offer additional benefits, including work permits and healthcare access starting November 2024.",
      "Naturalized citizens enjoy full rights, including voting, running for public office, and applying for federal jobs.",
      "Everyone in the U.S. has basic rights regardless of immigration status. These rights include freedom of speech, equality, safety, and privacy. Discrimination based on age, disability, national origin, race, religion, gender identity, or sexual orientation is illegal. For example, an employer cannot refuse to hire someone based on their accent or cultural background. If discrimination occurs, the victim can file a complaint with the Equal Employment Opportunity Commission (EEOC).",
      "You have the right to safe housing. Landlords cannot discriminate against you or charge extra based on immigration status. For example, if a landlord refuses to rent an apartment to a family because they are immigrants, this may violate the Fair Housing Act. Tenants can file a complaint with the Department of Housing and Urban Development (HUD) for investigation.",
      "Workers have the right to safe workplaces and fair wages. Discrimination and exploitation are illegal, even for undocumented workers. For example, if an employer refuses to pay wages owed to an undocumented worker, the worker can file a claim with the Department of Labor without fear of deportation.",
      "All children have the right to free public education. Schools cannot discriminate based on race or ethnicity. For example, a child of undocumented parents cannot be denied enrollment in a public school because of their immigration status. Schools must also provide language assistance to non-English speakers.",
      "Emergency medical care must be provided regardless of immigration status or ability to pay. For example, if an undocumented immigrant arrives at an emergency room with a severe injury, the hospital must provide treatment even if the patient cannot afford it. Patients can ask for financial assistance programs if needed.",
      "You have rights when dealing with law enforcement, including the right to remain silent, privacy, and legal representation. Officers need a warrant to search your home or belongings. For instance, if ICE agents knock on your door, you are not required to open it unless they show a signed warrant. You can request to speak to a lawyer before answering any questions.",
      "Immigrants have the right to apply for legal status and programs like asylum, Temporary Protected Status (TPS), or family reunification visas. For example, someone fleeing persecution in their home country can apply for asylum and receive protection in the U.S. while awaiting a decision on their case.",
      "Victims of domestic violence have access to help, including emergency shelter, counseling, and legal protections like U Visas and the Violence Against Women Act (VAWA). For instance, an undocumented immigrant facing domestic abuse can apply for a U Visa, which grants temporary legal status and protection from deportation.",
      "Stateless individuals and undocumented immigrants retain basic rights like education, healthcare, and protection against discrimination. Programs like DACA offer additional benefits, including work permits and healthcare access starting November 2024. For example, a DACA recipient can obtain a driver’s license, enroll in college, and apply for jobs legally.",
      "Naturalized citizens enjoy full rights, including voting, running for public office, and applying for federal jobs. For example, a former refugee who becomes a naturalized citizen can vote in local and national elections and sponsor family members for visas to join them in the U.S."
    ],
  },
    "Traffic Code": {
      paragraphs : [
    "Each state in the U.S. has its own laws about driving, including how to get a driver’s license and learner’s permit, as well as requirements for safety, car insurance, and vehicle registration.",
    "To drive in the U.S., you need a valid drivers license. Applicants must pass a vision test, written test, and road skills test. States issue different licenses for cars, trucks, motorcycles, and more. Learner’s permits are often required for first-time drivers, with additional rules for minors and adults.",
    "Undocumented immigrants can get licenses in 19 states and D.C., provided they meet specific requirements. Driving without a valid license can result in fines, jail time, or deportation proceedings. Foreign licenses may also be valid temporarily, depending on state laws.",
    "Traffic laws ensure road safety. Drivers must wear seatbelts, follow speed limits, and yield to emergency vehicles and school buses. Drunk driving, texting, or leaving pets/children unattended in vehicles are prohibited. Accidents must be reported to the police and insurance companies, and fleeing the scene is a crime.",
    "Safe driving involves maintaining your vehicle, focusing on the road, avoiding driving while tired, and preparing for emergencies. Carry a safety kit and be cautious during bad weather. Respond calmly to road rage and follow procedures if stopped by the police.",
    "When buying a car, research thoroughly. Used cars are usually more affordable, but ensure the car title is clear and get a mechanic’s inspection. You can pay in cash or apply for a loan, and proof of insurance is essential before driving.",
    "Car insurance is mandatory in 48 states and covers damages and injuries in accidents. Liability coverage is the minimum requirement, but comprehensive coverage offers broader protection. Keep proof of insurance in your car.",
    "Vehicle registration is required before driving. Registration involves obtaining a title, license plate, and registration card. Many states require annual safety inspections. Failure to register or maintain valid registration can result in fines and towing.",
    "Each state in the U.S. has its own laws about driving, including how to get a driver’s license and learner’s permit, as well as requirements for safety, car insurance, and vehicle registration. For example, in California, new drivers under 18 must hold a learner’s permit for at least six months and complete 50 hours of supervised driving before getting a license. Drivers should check their state’s Department of Motor Vehicles (DMV) website for specific requirements.",    
    "To drive in the U.S., you need a valid driver’s license. Applicants must pass a vision test, written test, and road skills test. States issue different licenses for cars, trucks, motorcycles, and more. For example, a motorcycle rider in Texas must pass a specific motorcycle safety course before obtaining a Class M license. Learner’s permits are often required for first-time drivers, with additional rules for minors and adults.",    
    "Undocumented immigrants can get licenses in 19 states and D.C., provided they meet specific requirements. For instance, in California, undocumented immigrants can apply for an AB 60 license by providing proof of identity and California residency. Driving without a valid license can result in fines, jail time, or deportation proceedings. Foreign licenses may also be valid temporarily, depending on state laws, such as those in New York, which allow tourists to drive with a valid foreign license.",    
    "Traffic laws ensure road safety. Drivers must wear seatbelts, follow speed limits, and yield to emergency vehicles and school buses. For example, in Florida, drivers must move over or slow down when approaching a stopped emergency vehicle. Drunk driving, texting, or leaving pets/children unattended in vehicles are prohibited. In Texas, leaving a child unattended in a car for more than 5 minutes is a Class C misdemeanor, punishable by a fine.",    
    "Safe driving involves maintaining your vehicle, focusing on the road, avoiding driving while tired, and preparing for emergencies. Carry a safety kit and be cautious during bad weather. For example, in Colorado, drivers are required to use snow tires or chains during certain winter months to prevent accidents in icy conditions. Respond calmly to road rage and follow procedures if stopped by the police. If pulled over, drivers should keep their hands visible and follow the officer’s instructions to avoid escalation.",    
    "When buying a car, research thoroughly. Used cars are usually more affordable, but ensure the car title is clear and get a mechanic’s inspection. For example, in Illinois, buyers must verify the title through the Secretary of State’s office to ensure there are no unpaid loans or liens. You can pay in cash or apply for a loan, and proof of insurance is essential before driving.",    
    "Car insurance is mandatory in 48 states and covers damages and injuries in accidents. Liability coverage is the minimum requirement, but comprehensive coverage offers broader protection. For instance, in California, drivers must carry a minimum liability coverage of $15,000 per person for injury. Keep proof of insurance in your car, as failing to provide it during a traffic stop can result in fines or license suspension.",    
    "Vehicle registration is required before driving. Registration involves obtaining a title, license plate, and registration card. Many states require annual safety inspections. For example, in New York, drivers must have their vehicle inspected yearly to ensure it meets safety and emissions standards. Failure to register or maintain valid registration can result in fines and towing, as seen in cases where unregistered vehicles were impounded by police."
  ],
},
  "Workers right": {
    paragraphs : [
      "In the USA, workers have rights to ensure fair treatment in the workplace. Employers must follow laws that protect workers’ pay, hours, and workplace conditions. Employers who violate these laws can be fined or punished.",
      "The federal minimum wage is $7.25 per hour. States may have higher minimum wages, and employers must pay the higher amount. Tipped employees must earn at least $7.25 per hour, including tips, with a base pay of $2.13 per hour.",
      "US laws require equal pay for equal work, regardless of gender. Employees doing the same job must receive the same wages, overtime, vacation hours, and bonuses, unless differences in position, skills, or experience justify otherwise.",
      "Discrimination in hiring or employment based on gender, race, religion, disability, age (40 or older), or national origin is illegal. Employees with disabilities have additional protections under the law.",
      "Work injuries must be reported immediately to a manager. Workers’ compensation laws ensure injured workers receive medical treatment and wage replacement. Employers are responsible for maintaining a safe workplace.",
      "Employees have a right to privacy for personal belongings such as bags and lockers. However, employers may monitor work emails and phone usage depending on company policy. Negative comments about the company should be avoided.",
      "Unfair termination occurs when an employee is fired for illegal reasons such as discrimination, harassment, or reporting injuries. Workers can seek legal help if they believe their termination was unjust.",
      "Employers must provide a safe and healthy workplace, free from hazards. They must inform and train employees about potential dangers in a language they understand. Employees should report unsafe conditions to their managers.",
      "Resources are available for workers to file complaints about discrimination, unsafe conditions, or unfair treatment. Legal assistance is also accessible for immigrants and refugees to protect their rights.",
      "In the USA, workers have rights to ensure fair treatment in the workplace. Employers must follow laws that protect workers’ pay, hours, and workplace conditions. Employers who violate these laws can be fined or punished. For example, a fast-food chain in California was fined $1.6 million for failing to pay overtime wages to its employees, highlighting the importance of labor law compliance.",      
      "The federal minimum wage is $7.25 per hour. States may have higher minimum wages, and employers must pay the higher amount. Tipped employees must earn at least $7.25 per hour, including tips, with a base pay of $2.13 per hour. For instance, in Washington state, the minimum wage is $15.74 per hour, and tipped workers must also receive this rate without relying solely on tips.",      
      "US laws require equal pay for equal work, regardless of gender. Employees doing the same job must receive the same wages, overtime, vacation hours, and bonuses, unless differences in position, skills, or experience justify otherwise. For example, a female engineer in New Jersey sued her employer after discovering her male colleagues earned 20% more for the same work. The company settled and updated its pay policies.",      
      "Discrimination in hiring or employment based on gender, race, religion, disability, age (40 or older), or national origin is illegal. Employees with disabilities have additional protections under the law. For example, a bakery in Colorado faced legal action after refusing to hire an applicant with a disability, violating the Americans with Disabilities Act. The company was ordered to pay damages and provide training on anti-discrimination policies.",      
      "Work injuries must be reported immediately to a manager. Workers’ compensation laws ensure injured workers receive medical treatment and wage replacement. Employers are responsible for maintaining a safe workplace. For instance, a construction worker in Texas who fell from scaffolding received compensation for medical expenses and lost wages after reporting the accident and filing a claim.",      
      "Employees have a right to privacy for personal belongings such as bags and lockers. However, employers may monitor work emails and phone usage depending on company policy. Negative comments about the company should be avoided. For example, an employee in Florida was terminated after making defamatory remarks about the company on social media. The court upheld the dismissal, emphasizing the importance of professional conduct.",      
      "Unfair termination occurs when an employee is fired for illegal reasons such as discrimination, harassment, or reporting injuries. Workers can seek legal help if they believe their termination was unjust. For instance, a woman in California won a lawsuit against her employer after being fired for reporting sexual harassment. She was awarded $1.5 million in damages.",      
      "Employers must provide a safe and healthy workplace, free from hazards. They must inform and train employees about potential dangers in a language they understand. Employees should report unsafe conditions to their managers. For example, a warehouse in Illinois was fined after failing to provide proper safety equipment to workers handling hazardous materials, leading to workplace injuries.",      
      "Resources are available for workers to file complaints about discrimination, unsafe conditions, or unfair treatment. Legal assistance is also accessible for immigrants and refugees to protect their rights. For example, a group of undocumented farmworkers in Florida successfully filed a complaint about unsafe working conditions and received legal protections against deportation through legal aid organizations."
    ],
},
    "Substance Abuse": {
      paragraphs : [
        "In the USA, it is illegal for people under the age of 21 to buy alcohol and cigarettes. However, in some states, individuals under 21 may drink alcohol with their parent’s permission in a private home.",
        "Public consumption of alcohol is prohibited in most states, and there are also restrictions on where smoking is allowed.",
        "Driving under the influence of alcohol is illegal nationwide. A blood alcohol concentration (BAC) of 0.08% or higher is considered intoxicated in most states.",
        "Producing, selling, and using certain controlled substances such as methamphetamine, cocaine, and heroin is illegal across the country. Marijuana remains illegal in most states, though exceptions exist.",
        "Recreational use of marijuana is legal in 19 states, while medical marijuana is permitted in 38 states.",
        "In the USA, it is illegal for people under the age of 21 to buy alcohol and cigarettes. However, in some states, individuals under 21 may drink alcohol with their parent’s permission in a private home. For example, in Texas, minors can legally consume alcohol if a parent or guardian is present and gives permission. However, providing alcohol to minors outside these circumstances can result in fines and criminal charges.",        
        "Public consumption of alcohol is prohibited in most states, and there are also restrictions on where smoking is allowed. For instance, in New York City, drinking alcohol in public parks or streets is illegal and may result in fines up to $250 or arrest. Similarly, smoking in restaurants, bars, and parks is banned, and violators may face penalties.",        
        "Driving under the influence of alcohol is illegal nationwide. A blood alcohol concentration (BAC) of 0.08% or higher is considered intoxicated in most states. For example, a driver in California was arrested for DUI after causing an accident while having a BAC of 0.12%. The driver faced license suspension, fines, and mandatory alcohol education classes.",        
        "Producing, selling, and using certain controlled substances such as methamphetamine, cocaine, and heroin is illegal across the country. For example, a man in Florida was sentenced to 10 years in prison for possessing and distributing methamphetamine. Federal and state laws impose strict penalties for drug trafficking and possession.",        
        "Recreational use of marijuana is legal in 19 states, while medical marijuana is permitted in 38 states. For instance, in Colorado, adults 21 and older can legally purchase and possess up to 1 ounce of marijuana. However, a tourist was fined for attempting to take marijuana across state lines into Nebraska, where it remains illegal, demonstrating the importance of knowing local laws.",
      ],
},
    "Child and parenting laws in the U.S." : {
      paragraphs: [
        "In the United States, parents and caregivers are legally responsible for providing for their children until they become adults, which is typically at 18 years old. Responsibilities include providing food, housing, health care, education, supervision, and financial support. Both parents are required to support their children, regardless of marital status.",
        "Child abuse and neglect are prohibited in all states. Abuse includes physical, emotional, sexual harm, or neglect of basic necessities like food, shelter, and supervision. Substance abuse exposing children to harm is also considered neglect. Certain professionals are mandated reporters of suspected abuse. Reports can be made anonymously.",
        "Most states do not specify a minimum age for leaving children home alone. Parents must assess their child’s maturity, health, and safety before leaving them unsupervised. Leaving children alone in unsafe situations may be considered neglect.",
        "Parents are required by law to ensure their children attend school, whether it’s public, private, or homeschool. They can request accommodations for their child’s needs and report issues like bullying to the school. Schools must address these concerns to ensure safety and inclusivity.",
        "Other laws include supervising teenage drivers with learner’s permits and prohibiting forced marriages. Parental consent may be required for marriage at 16-17 years old, depending on state laws.",
        "Immigrant families face unique challenges when adjusting to American culture. Parents are encouraged to talk with their children about their experiences, participate in local activities, and maintain cultural traditions. Resources are available to support immigrant families in parenting and mental health.",
        "Support for parents includes resources like Bridging Refugee Youth & Children Services, local child care assistance, and parenting hotlines such as the National Parent Hotline and Teen Line for emotional support. Services like 211 provide help with food, housing, and health in local communities.",
        "In the United States, parents and caregivers are legally responsible for providing for their children until they become adults, which is typically at 18 years old. Responsibilities include providing food, housing, health care, education, supervision, and financial support. Both parents are required to support their children, regardless of marital status. For example, in California, a non-custodial parent was ordered by the court to pay child support after failing to contribute financially, ensuring the child’s needs were met.",        
        "Child abuse and neglect are prohibited in all states. Abuse includes physical, emotional, sexual harm, or neglect of basic necessities like food, shelter, and supervision. Substance abuse exposing children to harm is also considered neglect. Certain professionals are mandated reporters of suspected abuse. Reports can be made anonymously. For example, a teacher in Florida reported suspected abuse, leading authorities to rescue a child from an unsafe home and place them in foster care.",        
        "Most states do not specify a minimum age for leaving children home alone. Parents must assess their child’s maturity, health, and safety before leaving them unsupervised. Leaving children alone in unsafe situations may be considered neglect. For instance, in Illinois, parents were charged with neglect after leaving their 6-year-old child home alone for several hours, prompting intervention by child protective services.",        
        "Parents are required by law to ensure their children attend school, whether it’s public, private, or homeschool. They can request accommodations for their child’s needs and report issues like bullying to the school. Schools must address these concerns to ensure safety and inclusivity. For example, a family in Texas successfully sued a school district for failing to protect their child from bullying, resulting in updated anti-bullying policies and training for staff.",        
        "Other laws include supervising teenage drivers with learner’s permits and prohibiting forced marriages. Parental consent may be required for marriage at 16-17 years old, depending on state laws. For example, in New York, a judge denied a petition for a 16-year-old’s marriage due to concerns about coercion, highlighting laws protecting minors from forced marriages.",        
        "Immigrant families face unique challenges when adjusting to American culture. Parents are encouraged to talk with their children about their experiences, participate in local activities, and maintain cultural traditions. Resources are available to support immigrant families in parenting and mental health. For instance, immigrant parents in California used free English classes and parenting workshops to help their children adapt to new schools and build stronger family relationships.",        
        "Support for parents includes resources like Bridging Refugee Youth & Children Services, local child care assistance, and parenting hotlines such as the National Parent Hotline and Teen Line for emotional support. Services like 211 provide help with food, housing, and health in local communities. For example, a single mother in Ohio received emergency housing assistance through 211 after losing her job, allowing her to keep her family safe and stable."
      ]
    },
    "Healthcare Law": {
      paragraphs: [
        "Everyone in the U.S. has the right to access emergency medical care, regardless of their ability to pay or immigration status. Hospitals must provide necessary treatment during emergencies.",
        "Individuals with insurance have the right to know their benefits and coverage. Insured patients should receive detailed explanations of their medical bills and coverage limits.",
        "Uninsured individuals can access low-cost or free care through community health centers and state-funded programs. Programs like Medicaid provide coverage for low-income families, seniors, and individuals with disabilities.",
        "Patients have the right to privacy and confidentiality of their medical records. The Health Insurance Portability and Accountability Act (HIPAA) ensures patient information is protected.",
        "If a medical error occurs, patients have the right to file complaints with healthcare providers, insurance companies, or state health boards.",
        "Everyone in the U.S. has the right to access emergency medical care, regardless of their ability to pay or immigration status. Hospitals must provide necessary treatment during emergencies. For example, a homeless man in New York City was treated for a heart attack in an emergency room without insurance. The hospital stabilized his condition and connected him with social services to help him apply for Medicaid.",        
        "Individuals with insurance have the right to know their benefits and coverage. Insured patients should receive detailed explanations of their medical bills and coverage limits. For instance, a woman in California disputed an unexpected medical bill after receiving treatment covered under her insurance plan. She filed an appeal with her insurance company and, with assistance from a patient advocate, had the charges reduced and clarified.",        
        "Uninsured individuals can access low-cost or free care through community health centers and state-funded programs. Programs like Medicaid provide coverage for low-income families, seniors, and individuals with disabilities. For example, a single mother in Texas enrolled in Medicaid after losing her job and was able to secure prenatal care and delivery services at no cost through the program.",        
        "Patients have the right to privacy and confidentiality of their medical records. The Health Insurance Portability and Accountability Act (HIPAA) ensures patient information is protected. For example, a nurse in Florida was fined and fired after sharing a patient’s health information without consent, demonstrating the strict enforcement of HIPAA regulations.",        
        "If a medical error occurs, patients have the right to file complaints with healthcare providers, insurance companies, or state health boards. For example, a patient in Illinois received the wrong medication during surgery, resulting in complications. The patient filed a complaint with the state medical board, which led to an investigation, disciplinary action against the provider, and financial compensation for the patient."
      ]
    },
    "Family Roles":{
      paragraphs : [
        "Family roles in the USA may differ from those in other countries, particularly regarding gender roles. In the USA, women often work outside the home, and household chores and childcare responsibilities are shared between men and women. Helping out at home is considered an important part of being a supportive family member, regardless of gender.",
        "Parenting in the USA can be challenging for newcomers. Children often adapt to life in the USA more quickly than their parents, learning English faster and sometimes acting as translators. Daycare centers are commonly used, and parents may rely on neighbors or other immigrant families for childcare. It is possible to balance embracing new parenting practices with preserving cultural traditions.",
        "Child abuse is illegal in the USA. Abuse includes physical harm, emotional harm, and neglect. Physical discipline, such as spanking, is allowed but not recommended. Emotional abuse includes constant criticism, threats, and withholding love or guidance. Neglect involves failing to meet a child’s basic needs, such as leaving them unsupervised or not providing adequate care.",
        "Grandparents often experience significant changes in their roles when immigrating to the USA. In many countries, older adults are highly respected and serve as sources of wisdom. However, in the USA, youth is often valued more, and older adults may feel less relevant. Sharing cultural knowledge and traditions is a vital role for grandparents, helping families stay connected to their heritage.",
        "Resources are available to support immigrant families, including organizations that assist older adults and offer information about senior centers. Guidance on child abuse and neglect is based on trusted sources, but legal advice should be sought from qualified professionals when needed.",
        "Family roles in the USA may differ from those in other countries, particularly regarding gender roles. In the USA, women often work outside the home, and household chores and childcare responsibilities are shared between men and women. Helping out at home is considered an important part of being a supportive family member, regardless of gender. For example, in California, a father who worked full-time as a software engineer also took on cooking and cleaning duties to support his wife, who worked as a nurse during night shifts, demonstrating shared responsibilities.",        
        "Parenting in the USA can be challenging for newcomers. Children often adapt to life in the USA more quickly than their parents, learning English faster and sometimes acting as translators. Daycare centers are commonly used, and parents may rely on neighbors or other immigrant families for childcare. For example, a refugee family in Texas relied on their teenage daughter to translate at school meetings and medical appointments, showcasing how children often help their families navigate language barriers.",        
        "Child abuse is illegal in the USA. Abuse includes physical harm, emotional harm, and neglect. Physical discipline, such as spanking, is allowed but not recommended. Emotional abuse includes constant criticism, threats, and withholding love or guidance. Neglect involves failing to meet a child’s basic needs, such as leaving them unsupervised or not providing adequate care. For instance, a family in Illinois faced investigation after leaving their 5-year-old child alone at home for several hours. The parents were required to complete parenting classes to regain custody of their child.",        
        "Grandparents often experience significant changes in their roles when immigrating to the USA. In many countries, older adults are highly respected and serve as sources of wisdom. However, in the USA, youth is often valued more, and older adults may feel less relevant. Sharing cultural knowledge and traditions is a vital role for grandparents, helping families stay connected to their heritage. For example, an immigrant grandmother in New York taught her grandchildren traditional cooking and language lessons, keeping their cultural heritage alive while helping them adjust to American culture.",        
        "Resources are available to support immigrant families, including organizations that assist older adults and offer information about senior centers. Guidance on child abuse and neglect is based on trusted sources, but legal advice should be sought from qualified professionals when needed. For example, an immigrant family in Florida connected with a local senior center for English language classes and social activities, helping grandparents feel more engaged and supported in their new environment."
      ]
    },
    "Hunting Laws":{
      paragraphs : [
        "In the USA, hunting wild animals such as ducks, deer, elk, and moose requires a state-issued license. Fishing in fresh or saltwater also typically requires a license, which can be obtained through state wildlife agencies.",
        "It is illegal to slaughter animals outside of private homes or approved slaughterhouses. Proper procedures must be followed to ensure humane treatment and compliance with state laws.",
        "Animal cruelty and neglect are strictly prohibited. This includes torturing, overworking, or killing animals unlawfully. Owners must provide adequate food, shelter, and medical care for their animals and cannot abandon them. Violations can result in severe penalties, including fines and imprisonment.",
        "In the USA, hunting wild animals such as ducks, deer, elk, and moose requires a state-issued license. Fishing in fresh or saltwater also typically requires a license, which can be obtained through state wildlife agencies. For example, a hunter in Wisconsin was fined $500 for hunting deer without a valid license during the hunting season. The hunter also faced confiscation of their firearm and hunting equipment.",        
        "It is illegal to slaughter animals outside of private homes or approved slaughterhouses. Proper procedures must be followed to ensure humane treatment and compliance with state laws. For instance, a man in California was arrested and fined $2,000 for illegally butchering livestock in his backyard without proper permits, violating animal welfare and health regulations.",        
        "Animal cruelty and neglect are strictly prohibited. This includes torturing, overworking, or killing animals unlawfully. Owners must provide adequate food, shelter, and medical care for their animals and cannot abandon them. Violations can result in severe penalties, including fines and imprisonment. For example, a woman in Texas was sentenced to six months in jail and fined $1,500 for neglecting her dog, which was found malnourished and without shelter. The dog was rescued, rehabilitated, and adopted by a new family."
      ] 
    },
    "Privacy and Data Protection Law": {
      paragraphs: [
    "In the United States, privacy laws regulate how personal information is collected, stored, and shared by businesses and organizations. The Federal Trade Commission (FTC) enforces consumer privacy protections and holds companies accountable for data breaches or misuse.",
    "The Health Insurance Portability and Accountability Act (HIPAA) protects the privacy of individuals' medical records and health information, ensuring they are not disclosed without consent.",
    "The Children's Online Privacy Protection Act (COPPA) requires parental consent for websites and services to collect personal information from children under 13 years old.",
    "The California Consumer Privacy Act (CCPA) grants California residents rights over their personal information, including the right to know what is being collected, request deletion, and opt-out of data sales.",
    "Individuals are encouraged to read the privacy policies of online services and exercise caution when sharing personal information on social media or unsecured websites. Using strong passwords and enabling two-factor authentication are recommended practices to protect personal data.",
    "In cases of identity theft or privacy violations, individuals can report the incident to the FTC, which provides guidance and resources to recover from such breaches.",
    "In the United States, privacy laws regulate how personal information is collected, stored, and shared by businesses and organizations. The Federal Trade Commission (FTC) enforces consumer privacy protections and holds companies accountable for data breaches or misuse. For example, in 2019, Facebook agreed to pay a $5 billion fine to the FTC for mishandling users’ personal data and violating privacy agreements, marking one of the largest penalties for data privacy violations.",        
    "The Health Insurance Portability and Accountability Act (HIPAA) protects the privacy of individuals' medical records and health information, ensuring they are not disclosed without consent. For instance, a hospital in New Jersey was fined $1.1 million after staff improperly accessed and disclosed patient records without authorization, violating HIPAA regulations.",        
    "The Children's Online Privacy Protection Act (COPPA) requires parental consent for websites and services to collect personal information from children under 13 years old. For example, in 2019, YouTube paid a $170 million settlement for violating COPPA by collecting personal data from children without parental consent and using it for targeted advertising.",        
    "The California Consumer Privacy Act (CCPA) grants California residents rights over their personal information, including the right to know what is being collected, request deletion, and opt-out of data sales. In 2021, Sephora faced fines under the CCPA for failing to honor consumers’ opt-out requests regarding the sale of their data, highlighting the importance of compliance with privacy regulations.",        
    "Individuals are encouraged to read the privacy policies of online services and exercise caution when sharing personal information on social media or unsecured websites. Using strong passwords and enabling two-factor authentication are recommended practices to protect personal data. For example, a social media influencer in Texas avoided identity theft by quickly updating passwords and enabling two-factor authentication after noticing suspicious login attempts on her accounts.",        
    "In cases of identity theft or privacy violations, individuals can report the incident to the FTC, which provides guidance and resources to recover from such breaches. For example, a man in Florida reported identity theft to the FTC after discovering fraudulent credit card accounts opened in his name. The FTC assisted him in freezing his credit, disputing charges, and preventing further misuse of his identity."
  ]
},
}
